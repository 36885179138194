import store from '@/store'
import financialStoreModule from '@/pagsmile/financialStoreModule'
import merchantStoreModule from '@/pagsmile/merchantStoreModule'
import commonStoreModule from '@/pagsmile/commonStoreModule'

export default function settlementJs() {
  const FINANCIAL_STORE_MODULE_NAME = 'financial'
  const MERCHANT_STORE_MODULE_NAME = 'merchant'
  const COMMON_STORE_MODULE_NAME = 'common'

  // Register module
  if (!store.hasModule(FINANCIAL_STORE_MODULE_NAME)) store.registerModule(FINANCIAL_STORE_MODULE_NAME, financialStoreModule)
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)
  if (!store.hasModule(COMMON_STORE_MODULE_NAME)) store.registerModule(COMMON_STORE_MODULE_NAME, commonStoreModule)

  const fetchMerchantOptions = (success, fail) => {
    store
      .dispatch('merchant/fetchDropDownMerchants')
      .then(response => {
        const { code, msg, data } = response.data
        const merchants = []
        if (code === '10000') {
          data.forEach(item => {
            const merchant = { label: item.merchant_name, value: item.merchant_no }
            merchants.push(merchant)
          })
          success(merchants)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const searchBankBillDetail = (params, success, fail) => {
    store
      .dispatch('financial/fetchBankBillDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchBankBillList = (success, fail) => {
    store
      .dispatch('financial/fetchBankBillList')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data.list)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const generateSettlementReport = (params, success, fail) => {
    store
      .dispatch('financial/generateSettlementReport', params)
      .then(response => {
        if (response.status === 200) {
          success(response.data.reports)
        } else {
          fail(response.status)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchSettlementReports = (params, success, fail) => {
    store
      .dispatch('financial/fetchSettlementReports', params)
      .then(response => {
        if (response.status === 200) {
          success(response.data.reports)
        } else {
          fail(response.status)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchUserSettlementReports = (params, success, fail) => {
    store
      .dispatch('financial/fetchUserSettlementReports', params)
      .then(response => {
        if (response.status === 200) {
          success(response.data.reports)
        } else {
          fail(response.status)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchSettlementInvoices = (params, success, fail) => {
    store
      .dispatch('financial/fetchSettlementReports', params)
      .then(response => {
        if (response.status === 200) {
          success(response.data.invoices)
        } else {
          fail(response.status)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchSettlementPayouts = (params, success, fail) => {
    store
      .dispatch('financial/fetchSettlementPayouts', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const querySalesStatementReportDetail = (params, success, fail) => {
    store
      .dispatch('financial/querySalesStatementReportDetail', params)
      .then(response => {
        if (response.status === 200) {
          success(response.data)
        } else {
          fail(response.status)
        }
      })
      .catch(() => { fail('error') })
  }

  const querySettlementReportDetail = (params, success, fail) => {
    store
      .dispatch('financial/querySettlementReportDetail', params)
      .then(response => {
        if (response.status === 200) {
          success(response.data)
        } else {
          fail(response.status)
        }
      })
      .catch(() => { fail('error') })
  }

  const updateSalesStatementDetail = (params, success, fail) => {
    store
      .dispatch('financial/updateSalesStatementDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const updateSettlementDetail = (params, success, fail) => {
    store
      .dispatch('financial/updateSettlementDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data.list)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const uploadReportFile = (params, success, fail) => {
    store
      .dispatch('financial/uploadReportFile', params)
      .then(response => {
        if (response.status === 200) {
          success()
        } else {
          fail(response.status)
        }
      })
      .catch(() => { fail('error') })
  }

  const confirmPayoutRequest = (params, success, fail) => {
    store
      .dispatch('financial/confirmPayoutRequest', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const rejectPayoutRequest = (params, success, fail) => {
    store
      .dispatch('financial/rejectPayoutRequest', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const downloadSettlementPayout = (params, success, fail) => {
    store
      .dispatch('financial/downloadSettlementPayout', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchSettlementPayoutDownloadList = (success, fail) => {
    store
      .dispatch('financial/fetchSettlementPayoutDownloadList')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const withdrawByAdmin = (params, success, fail) => {
    store
      .dispatch('merchant/withdrawByAdmin', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchCommonOptions = (success, fail) => {
    store
      .dispatch('common/fetchCommonOptions')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          store.commit('common/SET_CURRENCIES', data.currencies)
          store.commit('common/SET_COUNTRIES', data.countries)
          success(data)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }

  const queryMerchantAssets = (params, success, fail) => {
    store
      .dispatch('merchant/queryMerchantAssets', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const listPayoutAccountByMerchant = (merchantNo, success, fail) => {
    store
      .dispatch('merchant/listPayoutAccountByMerchant', merchantNo)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const getPayoutExchangeRate = (params, success, fail) => {
    store
      .dispatch('merchant/getPayoutExchangeRate', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  return {
    fetchMerchantOptions,
    searchBankBillDetail,
    fetchBankBillList,
    generateSettlementReport,
    fetchSettlementReports,
    fetchUserSettlementReports,
    fetchSettlementInvoices,
    fetchSettlementPayouts,
    querySalesStatementReportDetail,
    querySettlementReportDetail,
    updateSalesStatementDetail,
    updateSettlementDetail,
    uploadReportFile,
    confirmPayoutRequest,
    rejectPayoutRequest,
    downloadSettlementPayout,
    fetchSettlementPayoutDownloadList,
    withdrawByAdmin,
    fetchCommonOptions,
    queryMerchantAssets,
    listPayoutAccountByMerchant,
    getPayoutExchangeRate,
  }
}
