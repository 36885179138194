<template>
  <div>

    <b-modal
      id="modal-confirm"
      ref="modalConfirm"
      title="Confirm Withdrawal"
      ok-title="Submit"
      centered
      cancel-variant="outline-secondary"
      :no-close-on-backdrop="true"
      @hidden="clearConfirmInfo"
      @ok="confirmPayoutRequest"
    >
      <form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Fee Currency"
              label-for="fee-currency"
            >
              <v-select
                v-model="withdrawConfirmParams.fee_currency"
                :options="currenciesOptions"
                :clearable="false"
                :searchable="false"
                input-id="fee-currency"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Fee Amount"
              label-for="fee-amount"
            >
              <b-form-input
                id="fee-amount"
                v-model="withdrawConfirmParams.fee_amount"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Payout Currency"
              label-for="payout-currency"
            >
              <v-select
                v-model="withdrawConfirmParams.payout_currency"
                :options="currenciesOptions"
                :clearable="false"
                :searchable="false"
                input-id="payout-currency"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Payout Amount"
              label-for="payout-amount"
            >
              <b-form-input
                id="payout-amount"
                v-model="withdrawConfirmParams.payout_amount"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <b-modal
      id="modal-withdraw"
      ref="modalWithdraw"
      title="Submit a Withdrawal"
      ok-title="Submit"
      centered
      cancel-variant="outline-secondary"
      :no-close-on-backdrop="true"
      @hidden="clearWithdrawInfo"
      @ok="withdrawByAdmin"
    >
      <form>
        <b-row>
          <b-col md="12" class="mb-2">
            <label>Merchant</label>
            <v-select
              v-model="withdrawMerchantNo"
              :options="merchants"
              :clearable="false"
              :searchable="true"
              :reduce="val => val.value"
            />
          </b-col>
        </b-row>
        <b-row v-if="withdrawCurrencies">
          <b-col md="12" class="mb-2">
            <label>Withdraw To</label>
            <v-select
              v-model="withdrawData.payout"
              :options="withdrawToOptions"
              :clearable="false"
              :searchable="false"
              :reduce="val => val.value"
            />
          </b-col>
        </b-row>
        <b-row v-if="withdrawData.payout">
          <b-col md="12" class="mb-2">
            <label>Payout Account</label>
            <v-select
              v-model="payoutAccountId"
              :options="payoutAccounts"
              label="account_name"
              :clearable="false"
              :searchable="false"
              :reduce="val => val.id"
            />
          </b-col>
        </b-row>
        <b-row v-if="withdrawCurrencies">
          <b-col md="6">
            <b-form-group
              label="Payout Currency"
              label-for="payout-currency"
            >
              <v-select
                v-model="withdrawData.currency"
                :options="withdrawCurrencies"
                :clearable="false"
                :searchable="false"
                input-id="payout-currency"
                :reduce="val => val.value"
              >
                <template #option="{ label, balance }">
                  <span style="font-weight:bold">{{ label }}</span> {{ balance }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Payout Amount"
              label-for="payout-amount"
            >
              <b-form-input
                id="payout-amount"
                v-model="withdrawData.amount"
                type="number"
              />
            </b-form-group>
          </b-col>
          <!-- Arrival -->
          <b-col v-if="withdrawData.payout" md="6">
            <b-form-group
              label="Arrival Currency"
              label-for="arrival-currency"
            >
              <v-select
                v-model="withdrawData.arrival_currency"
                :options="payoutAccountCurrenciesOptions"
                :clearable="false"
                :searchable="false"
                input-id="arrival-currency"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="withdrawData.payout" md="6">
            <b-form-group
              label="Arrival Amount"
              label-for="arrived-amount"
            >
              <b-form-input
                id="arrival-amount"
                v-model="arrivalAmount"
                type="number"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col v-if="withdrawData.payout && withdrawData.exchange_rate" md="12">
            <b-form-group
              label="Exchange Rate"
              label-for="exchange-rate"
            >
              <b-form-input
                id="exchange-rate"
                v-model="withdrawData.exchange_rate"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Fee Currency"
              label-for="fee-currency"
            >
              <v-select
                v-model="withdrawData.fee_currency"
                :options="currenciesOptions"
                :clearable="false"
                :searchable="false"
                input-id="fee-currency"
                max-height="50px"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Fee Amount"
              label-for="fee-amount"
            >
              <b-form-input
                id="fee-amount"
                v-model="withdrawData.fee_amount"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Remark"
              label-for="payout-remark"
            >
              <b-form-input
                id="payout-remark"
                v-model="withdrawData.remark"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <b-card title="Filter">
      <b-row>
        <b-col
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Merchant</label>
          <v-select
            v-model="merchantNo"
            :options="merchants"
            :clearable="false"
            :searchable="true"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>

        <b-col
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            v-model="status"
            :options="statusOptions"
            class="w-100"
            :clearable="false"
            :reduce="val => val.value"
          />
        </b-col>

        <b-col
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date Range</label>
          <date-picker
            v-model="dateRange"
            value-type="format"
            format="YYYY-MM-DD"
            range
            confirm
            :shortcuts="shortcuts"
            :clearable="false"
          />
        </b-col>

        <b-col
          md="12"
          class="mb-md-0 mb-2"
        >
          <b-button
            class="mt-2"
            variant="primary"
            @click="doSearch"
          >
            Search
          </b-button>
          <b-button
            class="mt-2 ml-2"
            variant="primary"
            @click="doDownload"
          >
            Download
          </b-button>
          <b-button
            v-if="$can('write', 'Settlement.Payout')"
            class="mt-2 ml-5"
            variant="outline-danger"
            @click="showWithdrawWindow"
          >
            Withdraw
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body>
      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="records"
        primary-key="withdraw_id"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <template #cell(merchant)="data">
          {{ data.item.merchant_name || 'N/A' }}
          <small class="text-muted">@{{ data.item.merchant_no }}</small>
        </template>

        <!-- Column: Actions -->
        <template #cell(opt)="data">
          <div
            v-if="$can('write', 'Settlement.Payout') && data.item.status === 'Pending'"
            class="text-nowrap"
          >
            <feather-icon
              icon="CheckCircleIcon"
              size="16"
              class="cursor-pointer"
              @click="confirmPrompt(data.item)"
            />
            <feather-icon
              icon="XCircleIcon"
              size="16"
              class="cursor-pointer ml-1"
              @click="rejectPayoutRequest(data.item)"
            />
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BRow, BCol, BTable, BPagination, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import { showToast, showAlert } from '@/libs/tool'
import useSettlementJs from './settlement'

const {
  fetchMerchantOptions,
  fetchSettlementPayouts,
  confirmPayoutRequest,
  rejectPayoutRequest,
  downloadSettlementPayout,
  fetchCommonOptions,
  withdrawByAdmin,
  queryMerchantAssets,
  listPayoutAccountByMerchant,
  getPayoutExchangeRate,
} = useSettlementJs()

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
    vSelect,
    DatePicker,
  },
  filters: {
    numberFilter(value) {
      let realVal = ''
      if (value || value === 0) {
        realVal = parseFloat(value).toFixed(2)
      } else {
        realVal = '--'
      }
      return realVal
    },
  },
  data() {
    return {
      merchantNo: '',
      merchants: [],
      status: '',
      statusOptions: [
        { label: 'All', value: '' },
        { label: 'Submitted', value: 0 },
        { label: 'Completed', value: 1 },
        { label: 'Pending', value: 2 },
        { label: 'Rejected', value: 3 },
        { label: 'Failure', value: 4 },
      ],
      dateRange: [],
      shortcuts: [
        {
          text: 'Last 7 Days',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().subtract(6, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 30 Days',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().subtract(29, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 90 Days',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().subtract(89, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
      ],
      tableFields: [
        'withdraw_id',
        'merchant',
        { key: 'request_currency', label: 'Currency' },
        { key: 'request_amount', label: 'Amount' },
        'create_time',
        'update_time',
        'status',
        'opt',
        'desc',
      ],
      records: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      currentPage: 1,
      totalRecords: 0,
      currenciesOptions: [
        'BRL',
        'USD',
        'EUR',
      ],
      withdrawToOptions: [
        { label: 'Bank Account', value: false },
        { label: 'Payout Balance', value: true },
      ],
      payoutAccountId: '',
      payoutAccounts: [],
      payoutAccountCurrenciesOptions: [],
      withdrawMerchantNo: '',
      withdrawCurrencies: null,
      withdrawData: {
        currency: '',
        amount: '',
        fee_currency: '',
        fee_amount: '',
        remark: '',
        payout: false,
        account_id: '',
        withdraw_token: '',
        exchange_rate: '',
        arrival_currency: '',
        arrival_amount: '',
      },
      withdrawConfirmParams: {
        withdraw_id: '',
        fee_currency: '',
        fee_amount: 0,
        payout_currency: '',
        payout_amount: 0,
      },
    }
  },
  computed: {
    arrivalAmount() {
      if (this.withdrawData.amount === '') {
        return ''
      }
      if (this.withdrawData.currency === '') {
        return ''
      }
      if (this.withdrawData.arrival_currency === '') {
        return ''
      }
      const account = this.payoutAccounts.find(a => a.id === this.payoutAccountId)
      const payoutMerchantId = account.account_id
      if (payoutMerchantId === '') {
        return ''
      }
      const params = {
        merchantNo: this.withdrawMerchantNo,
        source: this.withdrawData.currency,
        target: this.withdrawData.arrival_currency,
        payout_id: this.payoutAccountId,
        amount: this.withdrawData.amount,
      }
      getPayoutExchangeRate(
        params,
        rate => {
          this.withdrawData.account_id = payoutMerchantId
          this.withdrawData.arrival_amount = rate.target_amount
          this.withdrawData.exchange_rate = rate.exchange_rate
          this.withdrawData.withdraw_token = rate.withdraw_token
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `Fetch payout exchange-rate fail with ${fail}`)
        },
      )
      return this.withdrawData.arrival_amount
    },
  },
  watch: {
    currentPage() {
      this.doSearch()
    },
    payoutAccountId(id) {
      this.payoutAccountCurrenciesOptions = []
      const account = this.payoutAccounts.find(a => a.id === id)
      this.payoutAccountCurrenciesOptions = account.supported_currencies
    },
    withdrawMerchantNo(merchantNo) {
      this.withdrawCurrencies = null
      if (merchantNo === 'All' || merchantNo === '') {
        return
      }
      queryMerchantAssets(
        merchantNo,
        assets => {
          const balances = []
          assets.balance.forEach(item => {
            const currency = Object.keys(item)[0]
            const option = {
              label: currency,
              value: currency,
              balance: item[currency],
            }
            balances.push(option)
          })

          this.withdrawCurrencies = balances
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `Fetch merchant Assets with ${fail}`)
        },
      )

      listPayoutAccountByMerchant(
        merchantNo,
        accounts => {
          this.payoutAccounts = accounts
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
  created() {
    this.dateRange = [moment().startOf('month').startOf('day').format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')]
    fetchMerchantOptions(
      merchants => {
        this.merchants = merchants
        this.merchantNo = this.merchants[0].value
      },
      fail => {
        showToast(this, 'Warning', `Fetch merchant optinos with ${fail}`)
      },
    )
    fetchCommonOptions(
      res => {
        this.currenciesOptions = []
        res.currencies.forEach(item => {
          this.currenciesOptions.push(item.code)
        })
      },
      fail => {
        showAlert(this, 'warning', 'Fail!', `Fetch common options fail with ${fail}`)
      },
    )
  },
  methods: {
    recalMetaData() {
      this.dataMeta.from = this.currentPage * 10 - (10 - 1)
      this.dataMeta.to = this.totalRecords - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalRecords
      this.dataMeta.of = this.totalRecords
    },
    doSearch() {
      this.records = []
      const [start, end] = this.dateRange
      const params = {
        merchant_no: this.merchantNo,
        status: this.status,
        page_num: this.currentPage,
        page_size: this.perPage,
        start_time: start,
        end_time: end,
      }
      fetchSettlementPayouts(
        params,
        records => {
          this.records = records.list

          this.totalRecords = records.total
          this.recalMetaData()
        },
        fail => {
          showToast(this, 'Warning', `Fetch records with ${fail}`)
        },
      )
    },
    doDownload() {
      const [start, end] = this.dateRange
      const params = {
        merchant_no: this.merchantNo,
        status: this.status,
        start_time: start,
        end_time: end,
      }
      downloadSettlementPayout(
        params,
        () => {
          this.$router.push({ name: 'settlement-payout-download' })
        },
        fail => {
          this.showToast('Warning', `Download failed with ${fail}`)
        },
      )
    },
    clearConfirmInfo() {
      this.withdrawConfirmParams = {
        withdraw_id: '',
        fee_currency: '',
        fee_amount: 0,
        payout_currency: '',
        payout_amount: 0,
        desc: '',
      }
    },
    confirmPrompt(item) {
      this.withdrawConfirmParams.withdraw_id = item.withdraw_id
      this.withdrawConfirmParams.fee_currency = item.fee_currency
      this.withdrawConfirmParams.fee_amount = item.fee_amount
      this.$refs.modalConfirm.show()
    },
    confirmPayoutRequest() {
      confirmPayoutRequest(
        this.withdrawConfirmParams,
        () => {
          showToast(this, 'Success', 'confirmed.')
          this.doSearch()
        },
        fail => {
          showToast(this, 'Warning', `confirm fail with ${fail}`)
        },
      )
    },
    clearWithdrawInfo() {
      this.withdrawMerchantNo = ''
      this.withdrawCurrencies = null
      this.withdrawData = {
        currency: '',
        amount: '',
        fee_currency: '',
        fee_amount: '',
        remark: '',
      }
    },
    showWithdrawWindow() {
      this.$refs.modalWithdraw.show()
    },
    withdrawByAdmin() {
      const params = {
        merchant_no: this.withdrawMerchantNo,
        data: this.withdrawData,
      }
      withdrawByAdmin(
        params,
        success => {
          showToast(this, 'Success', `${success}`)
        },
        fail => {
          showToast(this, 'Warning', `reject fail with ${fail}`)
        },
      )
    },
    rejectPayoutRequest(item) {
      this.withdrawConfirmParams.withdraw_id = item.withdraw_id
      this.withdrawConfirmParams.fee_currency = item.fee_currency
      this.withdrawConfirmParams.fee_amount = item.fee_amount
      this.withdrawConfirmParams.desc = 'rejected by admin'
      rejectPayoutRequest(
        this.withdrawConfirmParams,
        () => {
          showToast(this, 'Success', 'rejected.')
          this.doSearch()
        },
        fail => {
          showToast(this, 'Warning', `reject fail with ${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  height: 37px;
}
</style>
